import styled from 'styled-components';

export const Plus = styled.div`
  position: absolute;
  top: 50%;
  right: 7vw;
  transform: translate(0, -50%);

  svg {
    width: 2.29vw;
    height: 2.29vw;
  }
`

export const WrapCarousel = styled.div`
  @media (max-width: 1023px){
    display: none;
  }
`

export const SwiperSlide = styled.div`
  display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      

      > svg {
        opacity: 0.32;
        pointer-events: none;
        transition: 0.3s;
        // width: 40%;
        max-width: 40%;
        max-height: 30%;

        ${
          props => props.isWhite && 'fill: #ffffff;'
        }
      }

      ${
        props => props.isActive && `
          svg {
            // width: 100%;
            max-width: 58%;
            min-height: 75px;
            min-width: 18vw;
            opacity: 1;
            fill: #F9F8F6;
          }
          &.pozostali {
            svg {
              min-height: auto;
              fill: #232328 !important;
            }
          }
        `
      }

      &.synoptis {
        svg {
          max-height: 45%;
          margin-right: -2%;
        }
      }

      &.hasco {
        svg {
          max-height: 70%;
          margin-right: -1%;
        }
      }

      &.kruszwica {
        svg {
          max-height: 80%;
          margin-right: -2%;
        }
      }

      &.nutricia {
        svg {
          max-height: 60%;
          margin-right: -2%;
        }
      }

      &.browarfortuna {
        svg {
          max-height: 60%;
          margin-right: -2%;
        }
      }

      &.swiper-slide-active {
        svg {
          // width: 100%;
          max-width: 58%;
          min-height: 75px;
          min-width: 18vw;
          opacity: 1;
          fill: #F9F8F6;
        }
        &.pozostali {
          svg {
            min-height: auto;
          }
        }

        &.kruszwica {
          svg {
            max-height: 100%;
          }
        }

        &.hasco {
          svg {
            max-height: 131%;
            max-width: 69%;
            min-height: 83px;
            min-width: 28vw;
          }
        }

        &.kadefarm {
          svg {
            max-height: 100%;
          }
        }

        &.browarfortuna {
          svg {
            max-height: 100%;
            fill: #000000;
          }
        }
      }
`

