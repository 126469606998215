import { createGlobalStyle } from 'styled-components';


export const TesaGlobal = createGlobalStyle`

  .tesa {

    &__donuts {
      display: flex;
      justify-content: space-around;
      padding-top: calc(60vh - 3.3vw);
      margin-bottom: 5.729vw;
      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }

    &__knowledge {
      padding-bottom: 4vw;
    }

    &__section-1 {
      margin-top: 5.8vw;
    }

    &__section {
      margin-bottom: 5.8vw;
    }
    &__kampania {
      &__img-1 {
        margin-top: 7vh;
        margin-left: 4%;
        margin-right: -3%;
      }
    }

    &__stands {
      &__img-1 {
        margin-top: -2vh;
        margin-right: -5%;
        margin-left: -4.5vw;
        margin-bottom: -6vw;
      }
    }

    &__right {
      text-align: right !important;
    }

    &__inspiracje {
      &__img-1 {
        margin-top: -6vw;
        margin-right: 10%;
        margin-left: -6vw;
      }
    }
  }
`