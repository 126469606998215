import React, { useState } from 'react';
import { VideoWrap, Video, FullScreen } from './video.styled';

import { CloseMenu } from './../../menu/menu.styled'

const Player = (props) => {
  const [show, setShow] = useState(true);
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const hideVideo = () => {
    setShow(false)
  }
  return (
    <>
      <VideoWrap className={props.className} production={props.production} nonMarginable={props.nonMarginable}>
        <Video 
          width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen
          {...props.video}
        />
      </VideoWrap>
      {(getParameterByName("video") === props.id && show) && <FullScreen>
        <CloseMenu zIndex={5} onClick={hideVideo}>
          <svg width="35" height="35" viewBox="0 0 19.665 19.713">
            <g fill={props.video.color}>
              <g>
                <path d="M11.613 9.43l-.067-.067-.065-.065-.067-.067-8.9-8.9-.066-.067-.066-.065-.066-.067a.374.374 0 0 0-.527.021L.153 1.79a.374.374 0 0 0-.022.527l.067.066.065.066.067.066 8.9 8.9.067.067.065.065.067.067a.372.372 0 0 0 .526-.021l1.637-1.637a.372.372 0 0 0 .021-.526z"/>
                <path d="M8.051 9.43l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.373.373 0 0 1 .526.021l1.637 1.637a.375.375 0 0 1 .022.527l-.067.066-.065.066-.067.066-8.9 8.9-.067.067-.065.065-.067.067a.372.372 0 0 1-.526-.021L8.073 9.956a.373.373 0 0 1-.022-.526z"/>
              </g>
              <g>
                <path d="M11.613 10.284l-.067.066-.065.066-.067.067-8.9 8.9-.066.067-.066.065-.066.067a.374.374 0 0 1-.527-.021L.153 17.924a.372.372 0 0 1-.022-.526l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.372.372 0 0 1 .526.022l1.637 1.636a.374.374 0 0 1 .021.526z"/>
                <path d="M8.051 10.284l.067.066.065.066.067.067 8.9 8.9.067.067.065.065.067.067a.372.372 0 0 0 .526-.021l1.637-1.637a.373.373 0 0 0 .022-.526l-.067-.067-.065-.065-.067-.067-8.9-8.9-.067-.067-.065-.065-.067-.067a.372.372 0 0 0-.526.022L8.073 9.758a.375.375 0 0 0-.022.526z"/>
              </g>
            </g>
          </svg>
        </CloseMenu>
        <VideoWrap 
          width={true}
          production={props.production} nonMarginable={props.nonMarginable}>
          <Video 
            width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen
            {...props.video_popup}
          />
        </VideoWrap>
      </FullScreen> }
    </>
  );
}

export default Player
