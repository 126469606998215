import { createGlobalStyle } from 'styled-components';


export const HascoGlobal = createGlobalStyle`

  .hasco {
    &__section {
      margin-bottom: 5.8vw;
    }
    &__tv {
      &__player {
        margin-top: 0;
      }

      
      &__section-gifs {
        margin-top: 4vw;
        margin-bottom: 4vw;
        margin-right: 10%;
        display: flex;
        gap: 31px;

        @media (max-width: 1023px){
          margin: 0%;
          margin-bottom: 5vw;
          padding: 0 30px;
          display: grid;
        }

        &__player {
          position: relative;
          margin-right: 0;
          margin-top: 0;
          z-index: 3;
          margin-left: 3.64vw;
          overflow: hidden;
          padding-top: 0;
          height: 100%;

          @media (max-width: 1023px){
            padding-top: 180%;
            height:  auto;
            margin-left: 0;
            margin-bottom: 0;
            margin-top: 30px;
          }
        }

       
        
        &__video {
          width: 76%;

          @media (max-width: 1023px){
            width: 100%;
          }
        }
        &__inside {
          display: grid;
          gap: 31px;
        }
      }

      &__img-1 {
        margin-top: 7vh;
        margin-left: 3%;
        margin-right: 7%;
      }

      &__text-1 {

      }
    }

    &__social {
      &__img-1 {
        margin-top: 6vw;
        margin-right: 2%;
        margin-left: 2%;
      }
    }

    &__right {
      text-align: right !important;
    }

    &__tv {
      &__text-1 {
        padding-left: 40% !important;
        text-align: right !important;
        margin-bottom: -220px;

        @media (max-width: 1023px){
          padding-left: 0 !important;
          margin-bottom: 0;
        }
      }
    }

    &__session {
      &__img-1 {
        margin-bottom: -3vw;
        margin-right: -20%;
        margin-left: -7%;
      }
    }

    &__album {
      &__img-1 {
        margin-top: 6vw;
        margin-right: 5%;
        margin-left: 9%;
      }
    }
  }
`